table.-op-table {
    font-size: 10px;
}
td.-op-log-field {
    text-align: right;
    color: blue;
    font-weight: 900;
    padding-right: 5px;
}
td.-op-log-change abbr.-op-content {
    display: inline-block;
    max-width: 150px;
    border: 1px solid #eee;
    padding: 3px;
    color: red;
    border-radius: 5px;
}
td.-op-log-change abbr.-op-content:nth-child(1) {
    color: green;
}
td.-op-log-change span.-op-to {
    color: green;
    font-weight: bold;
    margin: 0 8px;
}

td.-op-log-change span.-op-null {
    color: white;
    font-weight: bold;
    background: red;
    padding: 0 3px;
    border-radius: 3px;
}
