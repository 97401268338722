.pair-table {
    padding: 10px;
    border: 1px solid #ccc;
    border-bottom: none;
    text-align: center;
}
.pair-table.last {
    border-bottom: 1px solid #ccc;
}
.pair-table.field {
    border-right: none;
    background: #efefef;
    text-align: right;
}
