.floatf {
  float: left;
}

.floatr {
  float: right;
}

.year-img{
  width: 80px;
  height: 100px;
}

.auth-magin >div{
  display: block;
  clear: both;
  margin: auto;
}

.jump-div{
  width: 200px;
  height: 80px;
  border: #222627 3px;
  color: rgba(22, 22, 22, 1);
  Line-height:80px;
  text-align: center;
  border-radius:25px;
  color: #fff;
}

.width-max {
  width: 100%;
  overflow: hidden;
  clear: both;
}

.width-4 {
  width: 40%;
}

.width-3 {
  width: 30%;
}

.border {
  border: 1px solid #000
}

.icon {
  margin-top: -10px;
  margin-right: 20px;
  font-size: 40px;
  width: 40px;
  height: 40px;
}

.pad5 {
  padding: 5px;
}

.mg5 {
  margin: 5px;
}

.icon-box {
  color: #999;
  padding: 10px;
}

.icon-box-title{
  font-size: 12px;
  margin-bottom: 0px;
}

.icon-box-value{
  color: #777;
  font-size: 16px;
}

.top-box {
  height: 180px;
  padding: 10px;
  float: left;
  border: 1px solid #fcfcfc;
}

.top-span-box {
  margin-top: 10px;
  width: 100%;
  font-size: 12px;
  clear: both;
}

.top-span {
  font-size: 10px;
  height: 20px;
  color: #aaa;
  width: 25%;
  text-align: center;
}

.top-span-last {
  font-size: 10px;
  height: 20px;
  color: #aaa;
  width: 40%;
  text-align: center;
}

.right-box {
  width: 95%;
  clear: both;
  margin: 0 0 15px 5px;
  border: 1px solid #eee;
  box-shadow: 2px 2px 2px #aaa;
}

.left-frame {
  width: 69%;
  padding: 3px;
  float: left;
  margin-bottom: 10px;
  border: 1px solid #eee;
  box-shadow: 2px 2px 2px #aaa;
}

.core-tab {
  color: #aaa;
  width: 180px;
}

.ant-tabs-tab-active .core-head {
  border-top: 3px solid #25c104;
  box-shadow: inset 0px 20px 20px -20px #d2fac9;
}
.core-head {
  height: 80px;
}

.core-head>.core-head-title {
  padding: 10px;
  float: left;
  width: 80px;
  color: #777;
}

.core-head>.core-head-icon {
  float: right;
  width: 60px;
  color: #333;
}

.core-body {
  font-size: 10px;
  height: 80px;
  color: #aaa;
}

.mgl30 {
  margin-left: 30px;
}

.title {
  padding: 10px;
  height: 48px;
  border-bottom: 1px solid #ccc
}

.title > .title-name {
  font-size: 16px;
  color: #111;
  float: left;
}

.title > .title-icon {
  font-size: 12px;
  color: #999;
  float: right;
}

.flow-box {
  width: 33%;
  float: left;
  border: 1px solid #fcfcfc;
}

.flow-box-top {
  padding: 20px;
  font-size: 10px;
  color: #666666;
}

.flow-box-value {
  font-size: 20px;
  color: #333;
}

.flow-box-span {
  color: #999;
}

.flow-box-span > .flow-box-value {
  font-size: 10px;
  margin-left: 10px;
}

.flow-table {
  width: 50%;
  float: left;
  margin-top: 20px;
}

.goods-box {
  width: 20%;
  float: left;
  border: 1px solid #fcfcfc;
}

.after-sale-box {
  width: 25%;
  float: left;
  border: 1px solid #fcfcfc;
}

.goods-right-box {
  width: 33%;
  height: 80px;
  padding: 20px;
  float: left;
}

.goods-left {
  width: 30%;
  padding: 20px 10px;
  float: left;
}

.goods-icon {
  float: left;
  padding-top: 10px;
}

.goods-abnormal{
  float: left;
  padding: 5px;
}

.goods-abnormal-value{
  padding-top: 10px;
  padding-left: 5px;
}

.img {
  height: 50px;
  width: 50px;
  background: #222627;
}

.goods-mid {
  width: 50%;
  float: left;
}

.goods-right {
  width: 20%;
  float: left;
  padding: 10px 20px;
}

.goods-right>span {
  float: right;
}

.right-title {
  height: 38px;
  font-size: 16px;
  padding: 7px 15px;
  border-bottom: 1px solid #ccc;
  color: #111;
}

.after-sale-table {
  width: 50%;
  float: left;
  margin-top: 20px;
}

.right-table {
  width: 100%;
  margin-top: 20px;
}

.my-tooltip {
  margin: 5px;
}

.percent-up{
  color: #db1919;
}

.percent-down{
  color: #58b315;
}

.business-box{
  width: 48%;
  height: 100%;
  margin-bottom: 10px;
  border: 1px solid #eee;
}

.business-mid{
  height: 750px;
}

.business-top{
  height: 500px;
}

.business-title{
  height: 38px;
  font-size: 16px;
  padding: 7px 15px;
  border-bottom: 1px solid #ccc;
  color: #111;
}

.business-title-span {
  margin-left: 20px;
  font-size: 14px;
  color: #7b7a7a;
}

.business-table-title {
  overflow: hidden;
  line-height: 33px;
  font-size: 16px;
  padding: 7px 15px;
  border-bottom: 1px solid #ccc;
  color: #111;
}

.business-title-botton {
  float: right;
}

.business-title-switch {
  float: right;
}

.business-mid-box{
  margin-top: 20px;
  width: 100%;
  height: 350px;
  border: 1px solid #eee;
}

.business-table-box{
  width: 100%;
  border: 1px solid #eee;
  box-shadow: 2px 2px 2px #aaa;
  margin-top: 20px;
}

.business-gmv {
  margin: 20px 0 0 50px;
}

.business-today-gmv {
  font-size: 30px;
  color: #990032;
  margin-right: 20px;
}

.business-last-gmv {
  margin-left: 10px;
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid red;
  position: relative;
  top: -13px
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #64de43;
  position: relative;
  bottom: -13px
}

.goods-table-box{
  width: 100%;
  border: 1px solid #eee;
  margin-top: 20px;
}

.goods-top-table-box{
  width: 100%;
  border: 1px solid #eee;
  margin-top: 20px;
}

.goods-map-box{
  width: 100%;
  margin-top: 20px;
}