html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.page-title .ant-breadcrumb-separator {
  display: none;
}

.ant-layout.ant-layout-has-sider {
  min-width: 1200px;
}

/*=============================================
=            dashboard daily            =
=============================================*/

.dashboard {
  color: #fff;
  background: #222627;
}

.dashboard-container {
  width: 100%;
}

.dashboard-daily {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 30px 10px;
  background: url(./assets/bg-1.jpg) center no-repeat;
  background-size: cover;
}

.dashboard-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dashboard__title,
.dashboard-time {
  flex: auto;
  width: 100%;
  margin: 4px 0;
}

.dashboard-time__item {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;
  line-height: 1.2;
  margin: 5px 0;
}

.dashboard-time__label {
  color: rgb(0, 250, 255);
  font-size: 18px;
  margin: 10px 0;
}

.dashboard-time__value {
  font-size: 22px;
  color: rgb(0, 250, 255);
}

.dashboard__logo {
  width: 80%;
  /* margin-left: -20px; */
}

.table--dark .ant-table {
  color: rgb(229, 48, 4);
  background-color: transparent;
}

.table--dark .ant-table-thead > tr > th {
  color: rgb(229, 48, 4);
  background-color: rgba(0, 0, 0, 0.4);
  border-color: rgb(0, 250, 255);
}

.table--dark .ant-table-tbody > tr > td {
  border: none;
}

.table--dark .ant-table-thead tr th {
  background-color: rgba(0, 0, 0, 1);
}

.table--dark tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.7);
}

.table--dark tr:nth-child(odd) td.ant-table-cell-fix-left {
  background-color: rgba(0, 0, 0, 1);
}

.table--dark tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.4);
}

.table--dark tr:nth-child(even) td.ant-table-cell-fix-left {
  background-color: rgba(0, 0, 0, 1);
}

.table--dark tr:hover {
  color: #000;
}

.table--dark .ant-table-thead > tr > th:nth-child(odd),
.table--dark .ant-table-tbody > tr > td:nth-child(odd) {
  color: rgb(0, 250, 255);
}

.table--dark .ant-table-thead > tr > th:first-child,
.table--dark .ant-table-tbody > tr > td:first-child {
  color: rgb(0, 250, 255);
  border-right: 1px solid #24272a;
  border-bottom: none;
}

.table--dark .ant-table-thead > tr > th:first-child {
  position: relative;
}

.table--dark .ant-table-thead > tr > th:first-child::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: inline-block;
  content: '';
  border-top: 4px solid rgb(0, 250, 255);
}

.dashboard-content {
  max-width: 100%;
  margin: 20px 0;
  overflow: auto hidden;
}

.table--dark .ant-table-placeholder .ant-empty-description {
  color: #fff;
}

.table--dark .ant-table-placeholder:hover .ant-empty-description {
  color: rgba(0, 0, 0, 0.25);
}

.table--dark,
.ant-table-tbody,
> tr.ant-table-row:hover,
> td.ant-table-cell-fix-left {
  background: #fafafa;
}

.ant-table-tbody > tr.contrast
{
  display: none;
}

.ant-table-tbody > tr.contrast > td
{
  background: rgb(213, 212, 212);
}


.table--dark .ant-table-tbody > tr.ant-table-row:hover > td{
  background: #000;
  color: #fff;
}

@media (min-width: 768px) {
  .dashboard__logo {
    width: 50%;
  }

  .dashboard-header {
    flex-direction: row;
    align-items: flex-start;
  }

  .dashboard__title {
    text-align: left;
  }

  .dashboard__logo {
    width: 65%;
  }

  .dashboard-time__item {
    align-items: flex-end;
  }
}

@media (min-width: 992px) {
  .dashboard-time {
    display: flex;
    justify-content: flex-end;
  }

  .dashboard-time__item + .dashboard-time__item {
    margin-left: 50px;
  }
}

@media (min-width: 1200px) {
  .dashboard-daily {
    padding: 40px 50px;
  }

  .dashboard__logo {
    width: 60%;
  }

  .dashboard-time__value {
    font-size: 24px;
  }
}

@media (min-width: 1440px) {
  .dashboard-daily {
    padding: 40px 100px;
  }
}

/*=====  End of dashboard daily  ======*/

/* edit drawer */
.column-group + .column-group {
  border-top: 1px solid #f0f0f0;
  padding-top: 24px;
}

/* toolTips */
.toolTipStyle p {
  margin-bottom: 0;
}

.clickLabel {
  cursor: pointer;
  border-bottom: 2px solid rgb(229, 48, 4);
}

.table__wrapper {
  width: 100%;
  background: #fff;
  overflow: auto scroll;
}

.custom-table,
.custom-table th,
.custom-table td {
  border: 1px solid #f0f0f0;
}

.custom-table th,
.custom-table td {
  padding: 8px;
}

.custom-table th {
  background-color: #fafafa;
}

.custom-table {
  width: max-content;
  min-width: 100%;
  table-layout: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.center {
  text-align: center;
}

.table-left {
  text-align: left;
}

.custom-table .head-category {
  background-color: #d8d5d5 !important;
}

.custom-table pre {
  font-family: inherit;
  text-align: left;
}

.ant-layout {
  min-height: 100vh;
}

/* review pp user */
.reviewPPUser {
  line-height: 30px;
}

.reviewPPUser div {
  display: inline-block;
  text-align: left;
}

.table-th-color > th {
  background-color: #f2f2f2;
}

.table-th-color th:nth-child(1) {
  background-color: #fff;
}

.table-th-color-other > th {
  background-color: #f2f2f2;
}

.table-th-color-other th:nth-child(1) {
  background-color: #fff;
}
.table-th-color-other th:nth-child(2) {
  background-color: #fff;
}

.table-th-color-none > th {
  background-color: #f2f2f2;
}

/* peopleyard left sticky */
.sticky-left {
  position: sticky;
  left: 0;
}

.sticky-title {
  left: 10px;
}

.sticky-second {
  left: 100px;
}

/* bbs braft editor */
.my-editor {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
}

.my-editor .bf-content,.DraftEditor-editorContainer {
  height: 300px;
}

.ant-upload-list-item-actions a{
  pointer-events: auto !important;
  opacity: 1 !important;
}

.bbs-review {
  border-bottom: 2px solid #1890ff;
  cursor: pointer;
}

/* bbs survey */
.survey-question {
  margin-bottom: 0;
}

.ant-tree-switcher {
  line-height: 32px;
}

.survey-tree .ant-form-item-explain {
  display: none;
}

.survey-tree .ant-form-item-required::before {
  display: none!important;
}

/* crowd funding progress*/
.progress{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress-inner {
  position: relative;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 15px;
}

.progress-bg {
    background-color: #1890ff;
    border-radius: 15px;
    height: 15px;
}

.progress-text {
  position: absolute;
  font-size: 12px;
  color: #ccc;
  left: 6px;
  top: -1px;
}

.progress-rate {
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.85);
}

.index-check {
  width: 280px;
  height: 100px;
  border: #222627 3px;
  color: rgba(22, 22, 22, 1);
  Line-height:100px;
  text-align: center;
  color: #fff;
}

.app-check {
  width: 100px;
  height: 100%;
  background:rgb(2, 21, 40);
  text-align: center;
  color: #fff;
  float: left;
  border: rgb(2, 21, 40);
}

.app-check:hover {
  background: rgb(37, 150, 250);
  color: #fff;
}

.color1 {
  background: rgba(49, 168, 186, 1);
}

.color2 {
  background: rgba(72, 207, 122, 1);
}

.color3 {
  background: rgba(252, 106, 9, 1);
}

.color4 {
  background: rgba(169, 203, 8, 1);
}

.color5 {
  background: rgb(13, 118, 243);
}

.color6 {
  background: rgb(7, 239, 192);
}

.color7 {
  background: rgb(243, 13, 239);
}

.color8 {
  background: rgb(243, 166, 13);
}

.color9 {
  background: rgb(0, 244, 255);
}

.color10 {
  background: rgb(78, 255, 88);
}

.color11 {
  background: rgb(213, 171, 152);
}

.color12 {
  background: rgb(215, 174, 246);
}

.year-div {
  width: 250px;
  height: 100px;
  padding: 35px;
  margin: 10px;
  border: #222627 3px;
  color: rgba(22, 22, 22, 1);
  Line-height:20px;
  text-align: center;
  color: #fff;
  float: left;
}

.year-small-div {
  width: 100px;
  padding: 10px;
  border: #222627 3px;
  color: rgba(22, 22, 22, 1);
  Line-height:20px;
  text-align: center;
  color: #fff;
  float: left;
}

.year-border-div {
  padding: 10px;
  margin: 0 30px 30px 0;
  border-radius:25px;
  float: left;
  position: relative
}

.creat-div {
  margin-bottom: 20px;
  text-align: center;
  line-height: 100px;
  height: 100px;
  font-size: 50px
}

.year-img{
  width: 80px;
  height: 100px;
}


.auth-magin >div{
  display: block;
  clear: both;
  margin: auto;
}

.jump-div{
  width: 200px;
  height: 80px;
  border: #222627 3px;
  color: rgba(22, 22, 22, 1);
  Line-height:80px;
  text-align: center;
  border-radius:25px;
  color: #fff;
}