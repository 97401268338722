.table-public-collection {
    .select-large-black {
        .ant-select-selector {
            background: black !important;
        }
        .ant-select-arrow {
            color: white !important;
        }
    }
    .select-large-gray {
        .ant-select-selector {
            background: #DADADA !important;
        }
        .ant-select-arrow {
            color: white !important;
        }
    }
}
.pub-collection-box {
    .button-pagination {
        padding-inline: 10px;
    }
}
