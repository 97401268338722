.order-processing-filter-2 {
    .ant-btn {
        height: 100%;
        span {
            white-space: normal;
        }
    }
}
.order-history-filter {
    .ant-btn {
        height: 100%;
        span {
            white-space: normal;
        }
    }
}
