.card-product {
    .ant-upload.ant-upload-select.ant-upload-select-text {
        width: 100% !important;
        aspect-ratio: 1;
    }

}

.upload-image-1688 {
    .ant-upload {
        width: 100%;
    }
}
