.exclusive-container {
    .button-pagination {
        padding-inline: 10px;
    }
    .search-exclusive {
        .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
            height: 60px;
        }
        .ant-btn.ant-btn-primary.ant-btn-lg.ant-input-search-button {
            height: 60px;
            width: 150px;
            background: black;
            font-size: 16px;
        }
    }
    .card-wrapper {
        .description {
            display: -webkit-box;
            overflow: hidden;
            line-clamp: 5;
            -webkit-line-clamp: 5;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            word-break: break-word;
        }
    }
}

