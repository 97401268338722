.black-input-container {
    .ant-select-selector {
        background: black !important;
    }
}

.gray-input-container {
    .ant-select-selector {
        background: #DADADA !important;
    }
    .ant-select-selection-placeholder {
        color: white;
    }
    .ant-select-arrow {
        color: white;
    }
}

.black-input-container {
    .ant-select-selection-placeholder {
        color: white;
    }
    .ant-select-arrow {
        color: white;
    }
}
